import * as React from 'react';
import { Stack } from '@mui/material';
import { ScatterChart } from '@mui/x-charts/ScatterChart';

export default function ScatterGraph({ question, answers }) {
  return (
    <Stack className='ml-10 font-poppins' direction="column" sx={{ width: '100%' }}>
      <h2 className="text-s font-normal font-poppins mb-4">{question}</h2>
      <ScatterChart
        height={300}
        dataset={answers.map((answer, index) => ({ x: index, y: answer.count, id: answer.option }))}
        series={[
          {
            label: 'Series A',
            data: answers.map((answer, index) => ({ x: index, y: answer.count, id: answer.option })),
          },
        ]}
      />
    </Stack>
  );
}
