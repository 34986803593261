import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const chartSetting = {
    xAxis: [
        {
            label: 'Count',
        },
    ],
    width: 480,
    height: 500,
};

export default function HorizontalBars({ question, answers }) {
    const options = answers.map(answer => answer.option);
    const counts = answers.map(answer => answer.count);

    return (
        <div>
            <h2 className="text-s font-normal mb-4">{question}</h2>
            <BarChart
                dataset={answers.map((answer, index) => ({ [answer.option]: answer.count, month: answer.option }))}
                yAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                series={options.map(option => ({ dataKey: option, label: 'Count' }))}
                layout="horizontal"
                {...chartSetting}
            />
        </div>
    );
}
