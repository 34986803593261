const { OpenAI } = require("openai");

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
});

function isValidMessage(message) {
    return Array.isArray(message) && message.every(msg =>
        typeof msg.role === 'string' && typeof msg.content === 'string'
    );
}

export async function sendMsgToOpenAI(message) {
    console.log("Sending message to OpenAI:", message);

    if (!isValidMessage(message)) {
        console.error("Invalid message format:", message);
        return "Sorry, the message format is incorrect.";
    }

    try {
        const response = await openai.chat.completions.create({
            model: "ft:gpt-4o-mini-2024-07-18:dowden-consulting-llc:relevantreach:9vr43AXW",
            messages: message,
            max_tokens: 3000,
            temperature: 1,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
        });


        if (response?.choices?.length > 0 && response.choices[0]?.message?.content) {
            return response.choices[0].message.content;
        } else {
            console.error("Unexpected response structure from OpenAI:", response);
            return "Sorry, I couldn't generate a response at the moment.";
        }
    } catch (error) {
        console.error("Error communicating with OpenAI:", error);
        return "Sorry, there was an error processing your request.";
    }
}
