import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

export default function BasicPie({ question, answers }) {
  const data = answers.map((answer, index) => ({ id: index, value: answer.count, label: answer.option }));

  return (
    <div className="flex justify-center sm:justify-end sm:pr-20">
      <h2 className="text-s font-normal mb-4">{question}</h2>
      <PieChart
        className=''
        series={[
          {
            data,
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          },
        ]}
        height={200}
      />
    </div>
  );
}
