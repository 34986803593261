import React, { useEffect, useState } from 'react';
import Header from './Header';
import Side from './Side';
import PaymentComponent from './PaymentComponent';
import axios from 'axios';
import Subscription from './UserSubscriptions';
import { ClipLoader } from 'react-spinners';
import { user } from 'fontawesome';
import { useAuth } from '../api/AuthContext';
import { useNavigate } from 'react-router-dom';
import AIbot from './AIbot';

const Subscribe = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();





  const fetchSubscription = async () => {
    try {
      const response = await axios.get('https://onetapaisolutions.com/relevantreach/backend/public/api/user/latest-subscription', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tk')}`,
        },
      });
      console.log(response.data);
      setSubscription(response.data.data.subscription);
      setIsActive(response.data.data.subscription.is_subscribed === 1);

    } catch (error) {
      setError('Failed to fetch subscription data');
      setIsActive(false);
      setSubscription(null);
      setLoading(false);

    }
  };

  const fetchPackages = async () => {
    try {
      const response = await axios.get(`https://onetapaisolutions.com/relevantreach/backend/public/api/packages`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching packages:', error);
      throw error;
    }
  };

  useEffect(() => {
    const loadPackages = async () => {
      await fetchSubscription();
      try {
        const data = await fetchPackages();
        setPlans(data);
      } catch (error) {
        console.log('Error fetching packages:', error);
        setError('Error fetching packages');
      } finally {
        setLoading(false);
      }
    };


    loadPackages();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader color="#2130B7" loading={loading} size={50} />
      </div>
    );
  }

  const onCancelSubscription = async () => {
    setLoading(true);

    try {
      var rsp = await axios.post('https://onetapaisolutions.com/relevantreach/backend/public/api/cancel-subscription', {
        subscription_id: subscription.transaction_id,
        user_id: user.data.user.id
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tk')}`,
        },
      });
      setLoading(false);
      alert('Subscription cancelled successfully');
      console.log('Subscription cancelled ');
      console.log(rsp.data);
      setSubscription(null);
      setIsActive(false);
    }
    catch (error) {
      setLoading(false);
      console.error('Error cancelling subscription:', error);

    }
  }


  // onUpgrade
  const onUpgrade = async () => {
    if (isActive) {
      setIsUpgrade(true);
      try {
        const response = await axios.post('https://onetapaisolutions.com/relevantreach/backend/public/api/upgrade-subscription', {
          subscription_id: subscription.transaction_id,
          user_id: user.data.user.id,
          new_plan_id: 2
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tk')}`,
          },
        });
        setLoading(false);
        alert('Subscription upgraded successfully');
        console.log('Subscription upgraded ');
        console.log(response.data);
        setSubscription(response.data.data.subscription);
        setIsActive(true);
      }
      catch (error) {
        setLoading(false);
        console.error('Error upgrading subscription:', error);
      }
    }
    else {
      handlePayment();

    }
  }

  const handlePayment = async () => {



    //  navigate('/payment'); with state subscription.stripe_price_id as amount



    const plan = plans.find(plan => plan.package_id === subscription.package_id);
    const amount = plan.amount;


    navigate('/payment', { state: { amount, plan } });


  };






  return (
    <div className="flex h-screen bg-gradient-to-r from-[#2130B7] to-[#AD6E7F]" >
      <Side />
      <div className="flex-1 flex flex-col">
        <Header />
        <main className="flex-1 overflow-y-auto p-6">
          <div className='mb-10 rounded-xl font-poppins'>
            <Subscription subscription={subscription} isActive={isActive} onCancel={onCancelSubscription} onUpgrade={onUpgrade} />
          </div>
          {((!isActive) || (isUpgrade)) && (
            <div className="max-w-6xl mx-auto">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {plans.map((plan, index) => (
                  <div className='mb-10 rounded-xl font-poppins'>
                    <div
                      key={index}
                      className="hover:scale-105 hover:border-purple-500 bg-white shadow-lg border border-gray-200 p-8 text-center hover:shadow-xl transform transition duration-300"
                    >
                      <div className="mb-4">
                        <h2 className="text-lg font-semibold text-gray-900">{plan.title}</h2>
                      </div>
                      <div className="text-orange-500 text-5xl font-bold flex items-center justify-center mb-4">
                        <span>$</span>
                        <span>{plan.amount}</span>
                        <span className="text-xl font-medium text-gray-400 ml-1">/mo</span>
                      </div>
                      <ul className="text-gray-700 space-y-3">
                        {plan.features.map((feature, idx) => (
                          <li key={idx} className="font-medium">{feature}</li>
                        ))}
                      </ul>
                      <div className="mt-6">
                        <PaymentComponent plan={plan} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

          )}
        </main>
        <AIbot />
      </div>
    </div >
  );
};

export default Subscribe;
