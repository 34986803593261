import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useAuth } from '../api/AuthContext';
import register from '../api/auth';
import { ClipLoader } from 'react-spinners';

const Register = () => {
    const { user, loading } = useAuth();
    const navigate = useNavigate();
    const [inputData, setInputData] = useState({
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        acceptPrivacyPolicy: false
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!loading && user) {
            navigate('/dashboard', { replace: true });
        }
    }, [user, loading, navigate]);

    const validateForm = () => {
        const schema = Yup.object().shape({
            name: Yup.string().required('Name required.').min(2, 'Name must be 2 characters or more.'),
            email: Yup.string().email('Invalid email address.').required('Email required.'),
            password: Yup.string().required('Password required.').min(6, 'Password must be 6 characters or more.'),
            confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match.'),
            acceptPrivacyPolicy: Yup.bool().oneOf([true], 'Accepting the Privacy Policy is required.')
        });

        return schema.validate(inputData, { abortEarly: false })
            .then(() => {
                setErrors({});
                return true;
            })
            .catch((err) => {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                setErrors(validationErrors);
                return false;
            });
    };

    const handleData = (e) => {
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setInputData({ ...inputData, [name]: checked });
    };

    const sendForm = async (e) => {
        e.preventDefault();
        const isValid = await validateForm();
        if (isValid) {
            try {
                const response = await register(inputData);
                console.log(response.data);
                console.log(response.data.authorisation);
                localStorage.setItem("tk", response.data.authorisation.token);

                toast.success(response.data.message);
                navigate('/login', { replace: true });
            } catch (error) {
                toast.error(error.message || 'Something went wrong!');
            }
        }
    };

    if (loading) {
        return <div className="flex items-center justify-center min-h-screen">
            <ClipLoader color="#2130B7" loading={loading} size={60} />
        </div>;
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-[#101622] p-4">
            <div className="flex flex-col md:flex-row w-full max-w-4xl bg-[#8de7eb] rounded-lg shadow-md overflow-hidden">
                <div className="hidden md:flex md:w-1/2 p-8 bg-[#924BD5] items-center justify-center">
                    <img
                        src="/v2-register-light.png"
                        alt="Register illustration"
                        className="w-full h-auto"
                    />
                </div>
                <div className="w-full md:w-1/2 p-8 flex flex-col justify-center">
                    <img
                        src="/mainlogo.png"
                        alt="Register illustration"
                        className="w-full h-auto mb-4"
                    />
                    <p className="text-gray-600 mb-4 text-center md:text-left">
                        Please create your account and start the adventure
                    </p>
                    <form onSubmit={sendForm} className="space-y-4">
                        <div>
                            <label className="block text-gray-700">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={inputData.name}
                                onChange={handleData}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
                            />
                            {errors.name && <div className="text-red-500">{errors.name}</div>}
                        </div>
                        <div>
                            <label className="block text-gray-700">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={inputData.email}
                                onChange={handleData}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
                            />
                            {errors.email && <div className="text-red-500">{errors.email}</div>}
                        </div>
                        <div>
                            <label className="block text-gray-700">Password</label>
                            <input
                                type="password"
                                name="password"
                                value={inputData.password}
                                onChange={handleData}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
                            />
                            {errors.password && <div className="text-red-500">{errors.password}</div>}
                        </div>
                        <div>
                            <label className="block text-gray-700">Confirm Password</label>
                            <input
                                type="password"
                                name="confirm_password"
                                value={inputData.confirm_password}
                                onChange={handleData}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
                            />
                            {errors.confirm_password && <div className="text-red-500">{errors.confirm_password}</div>}
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="acceptPrivacyPolicy"
                                checked={inputData.acceptPrivacyPolicy || false}
                                onChange={handleCheckbox}
                                className="form-checkbox h-4 w-4 text-indigo-600"
                            />
                            <span className="ml-2 text-gray-700">
                                I accept the <a href="/privacy" target="_blank" className="text-indigo-600 hover:underline">Privacy Policy</a>
                            </span>
                        </div>
                        {errors.acceptPrivacyPolicy && <div className="text-red-500">{errors.acceptPrivacyPolicy}</div>}
                        <button
                            type="submit"
                            className="w-full bg-indigo-600 text-white py-2 rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-300"
                        >
                            Sign Up
                        </button>
                    </form>
                    <div className="mt-6 text-center">
                        <p className="text-gray-600">
                            Already have an account?{' '}
                            <a href="/login" className="text-indigo-600 hover:underline">
                                Log In
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
