import React from 'react';

const ChatToggleButton = ({ toggleChat }) => (
    <div className="fixed bottom-6 right-6 z-50 rounded-lg">
        <button
            onClick={toggleChat}
            className="bg-purple-600 text-white p-4 rounded-full shadow-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
        >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16h6M21 12c0-4.418-3.582-8-8-8s-8 3.582-8 8 3.582 8 8 8c1.758 0 3.4-.564 4.732-1.532L20 20l-.268-1.268A7.963 7.963 0 0021 12z"></path>
            </svg>
        </button>
    </div>
);

export default ChatToggleButton;
