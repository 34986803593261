import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AnimatedText from './AnimatedText';

const ChatWindow = ({ messages, input, handleInputChange, handleSend, handleEnter, msgEnd, isTyping, handleClone, closeChat }) => {
    const navigate = useNavigate();
    const chatEndRef = useRef(null);

    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, isTyping]);

    // 

    const renderButtons = (message) => {
        const buttons = [];

        if (message.isBot) {
            // Specific keys for buttons
            if (message.text.includes('[KEY: BOOK_SESSION]')) {
                buttons.push(
                    <button
                        key="book_session"
                        onClick={() => window.location.href = 'https://calendly.com/bookaconsultation-bodc/relevant-reach-product-demo?back=1&month=2024-08'}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg inline-block hover:bg-blue-600 mt-2"
                    >
                        Book Session
                    </button>
                );
                message.text = message.text.replace('[KEY: BOOK_SESSION]', '');
            }

            if (message.text.includes('[KEY: REACH_OUT]')) {
                buttons.push(
                    <button
                        key="reach_out"
                        onClick={() => window.location.href = 'mailto:info@relevantreact.io'}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg inline-block hover:bg-green-600 mt-2"
                    >
                        Reach Out Via Email
                    </button>
                );
                message.text = message.text.replace('[KEY: REACH_OUT]', '');
            }

            // General keywords triggering buttons
            const surveyRelatedKeywords = [
                'how to create survey',
                'create survey',
                'create a survey',
                'start survey',
                'make a survey',
                'build a survey',
                'survey creation',
                'launch survey',
                'begin survey'
            ];

            if (surveyRelatedKeywords.some(keyword => message.text.toLowerCase().includes(keyword))) {
                buttons.push(
                    <button
                        key="create_survey"
                        onClick={() => navigate('/create-survey')}
                        className="bg-purple-500 text-white px-4 py-2 rounded-lg inline-block hover:bg-purple-600 mt-2"
                    >
                        Create Survey
                    </button>
                );
            }

            const bookingRelatedKeywords = [
                'book session',
                'book a session',
                'schedule session',
                'schedule a session',
                'reach out',
                'book a time',
                'consultation',
                'product demo',
                'demo session',
                'schedule meeting'
            ];

            if (bookingRelatedKeywords.some(keyword => message.text.toLowerCase().includes(keyword))) {
                buttons.push(
                    <button
                        key="book_session"
                        onClick={() => window.open('https://calendly.com/bookaconsultation-bodc/relevant-reach-product-demo?back=1&month=2024-08', '_blank')}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg inline-block hover:bg-blue-600 mt-2"
                    >
                        Book Session
                    </button>
                );
            }

            const supportRelatedKeywords = [
                'contact support',
                'contact us',
                'reach out via email',
                'email support',
                'support team',
                'customer support',
                'get help',
                'technical support',
                'help desk'
            ];

            if (supportRelatedKeywords.some(keyword => message.text.toLowerCase().includes(keyword))) {
                buttons.push(
                    <button
                        key="contact_support"
                        onClick={() => window.location.href = 'mailto:info@relevantreact.io'}
                        className="bg-red-500 text-white px-4 py-2 rounded-lg inline-block hover:bg-red-600 mt-2"
                    >
                        Contact Support
                    </button>
                );
            }

            const subscribeRelatedKeywords = [
                'upgrade',
                'subscribe',
                'subscription',
                'premium',
                'purchase credits',
                'buy credits',
                'buy subscription',
                'subscribe to premium',
                'upgrade plan',
                'premium features',
                'subscribe now'
            ];

            if (subscribeRelatedKeywords.some(keyword => message.text.toLowerCase().includes(keyword))) {
                buttons.push(
                    <button
                        key="subscribe"
                        onClick={() => navigate('/subscribe')}
                        className="bg-yellow-500 text-white px-4 py-2 rounded-xl inline-block hover:bg-yellow-600 mt-2"
                    >
                        Upgrade/Subscribe
                    </button>
                );
            }
        }

        return buttons;
    };


    return (
        <div className="fixed bottom-10 right-6 z-50 bg-white font-poppins border border-gray-300 rounded-3xl shadow-lg w-80 sm:w-96 md:w-104 lg:w-120 flex flex-col h-[500px]">
            <div className="bg-blue-600 text-white p-3 rounded-t-3xl flex justify-between items-center font-poppins">
                <div className="flex items-center rounded-lg font-poppins">
                    <div className="rounded-full bg-white p-1 mr-2">
                        <img src="/logo512.png" alt="ChatBot" className="w-6 h-6" />
                    </div>
                    <div>
                        <h3 className="font-semibold font-poppins">Relevant Reach AI</h3>
                        <p className="text-sm font-poppins">Online</p>
                    </div>
                </div>
                <button onClick={closeChat} className="text-white">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>
            <div className="flex-1 overflow-y-auto p-4 bg-gray-100 text-black border-b border-gray-300 font-poppins">
                {messages.map((message, index) => (
                    <div key={index} className={`mt-2 font-poppins ${message.isBot ? 'self-start' : 'self-end'}`}>
                        <div className="flex items-center mb-1">
                            {message.isBot && (
                                <div className="rounded-full font-poppins bg-white p-1 mr-2">
                                    <img src="/logo512.png" alt="ChatBot" className="w-6 h-6" />
                                </div>
                            )}
                            <span className={`${message.isBot ? 'text-blue-600 font-poppins' : 'text-gray-600 font-poppins'} text-xs font-semibold font-poppins`}>
                                {message.isBot ? 'RR AI' : 'You'}
                            </span>
                        </div>
                        <div className={`p-3 rounded-3xl font-poppins ${message.isBot ? 'bg-white' : 'bg-blue-500 text-white'}`}>
                            <AnimatedText text={message.text.trim()} />
                            <div>{renderButtons(message)}</div>
                        </div>
                    </div>
                ))}
                {isTyping && (
                    <div className="mt-2 p-2 rounded-lg bg-gray-200 self-start font-poppins">
                        <p className="typing-dots font-poppins">...</p>
                    </div>
                )}
                <div ref={chatEndRef} />
            </div>
            <div className="bg-gray-100 p-2 border-t font-poppins border-gray-300 flex items-center rounded-b-3xl">
                <input
                    placeholder="Type your message"
                    value={input}
                    onKeyDown={handleEnter}
                    onChange={handleInputChange}
                    className="bg-white flex-grow p-2 rounded-full border border-gray-300 outline-none resize-none"
                />
                <button onClick={handleSend} className="p-1 bg-gray-200 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6 text-gray-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default ChatWindow;
