import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen flex  items-center justify-center bg-gray-100 p-4">
            <div className="w-full max-w-4xl bg-white rounded-lg shadow-md p-8">

                {/* Logo */}
                <div className="flex items-center justify-center">

                    <img src="/mainlogo.png" alt="Relevant Reach" className="w-64 h-auto" />
                    <p>
                        <p className="text-lg inline mb-2 ml-3">Personal Information<p className="text-lg  mb-2 ml-6">Privacy Policy</p></p>

                    </p>
                </div>

                <div className="overflow-x-auto">
                    <h2 className="text-xl font-semibold mb-2 mt-6">Data Collection</h2>
                    <p className="mb-4">
                        1. We collect your personally identifiable information (PII) only if you choose to give it to us.
                    </p>
                    <p className="mb-4">
                        2. If you provide us with your PII, we will use it as follows:
                        <ul className="list-disc list-inside">
                            <li>We may correspond with you directly, one-on-one.</li>
                            <li>We may send you marketing communications regarding the types of products and services you have expressed an interest in.</li>
                            <li>We may send you newsletters regarding topics related to those products and services.</li>
                            <li>We may sell or share it with other companies and organizations that are involved in such areas. (For example, if you express an interest in outdoor hiking events, we might forward your PII to an association that organizes such events.)</li>
                            <li>Although we won’t sell your PII to data brokers, we will try to use the PII you provide to narrow the types of communications we send you, and to narrow the types of companies and organizations to whom we sell or share your PII.</li>
                            <li>Although we don’t currently do so, we may combine the PII you give us with other data about you that we acquire from third parties, to even more accurately categorize you and your interests for the above purposes.</li>
                            <li>We will de-identify and/or anonymize your PII and use it for analytical purposes, including saving the de-identified/anonymized data indefinitely for purposes of longitudinal (long-term) analytics, and for machine learning and artificial intelligence training.</li>
                        </ul>
                    </p>
                    <p className="mb-4">
                        3. We will NOT use your PII in any of the following ways:
                        <ul className="list-disc list-inside">
                            <li>We will not disclose your PII publicly.</li>
                            <li>We will not share your PII with any company or organization for purposes other than normal commercial marketing.</li>
                        </ul>
                    </p>
                    <p className="mb-4">
                        4. You may notify us to stop sending you communications:
                        <ul className="list-disc list-inside">
                            <li>To stop receiving text messages, send the word STOP in response to any text message.</li>
                            <li>To stop receiving email messages, click on the “unsubscribe” link or if the particular email does not have the link, reply to the email with the word “unsubscribe” in the subject line.</li>
                            <li>We comply with the federal “do not call” list, but keep in mind that it only applies to companies that do not have an existing business relationship with you.</li>
                            <li>If we have sold or shared your PII to any third parties, we will notify them of any unsubscribe instructions, but please respond to any third-party emails directly since we cannot control third-party responses.</li>
                        </ul>
                    </p>
                    <p className="mb-4">
                        5. You may request that we correct or delete your PII. If you want us to correct or delete your PII, we will be happy to do so. Just let us know; before doing so we will respond with a message requesting confirmation of your request. For administrative purposes, we will keep copies of your communications with us, and for proper recordkeeping purposes we reserve the right to maintain archival (inactive) copies of your PII communications. In the unlikely event of a data breach, we will comply with all applicable data breach laws regarding notification, remediation, etc.
                    </p>
                    <p className="mb-4">
                        6. Other than as described in item 2 above, we will not release to any third party your PII or the contents of your communications with us, except to the extent we are required to do so as a result of legal process, or to the extent you specifically permit us to do so.
                    </p>
                    <h2 className="text-xl font-semibold mb-2 mt-6">State Privacy Laws</h2>
                    <p className="mb-4">
                        Please note that pursuant to Cal. Civ. Code §1798.140(d)(1)(A) we are exempt from the California Consumer Privacy Act; for similar reasons we are exempt from similar consumer privacy laws in other states.
                    </p>
                    <h2 className="text-xl font-semibold mb-2 mt-6">EU Residents</h2>
                    <p className="mb-4">
                        We do not intentionally target people in the EU and thus we are exempt from the GDPR. See EDPB, “Guidelines 05/2021 on the Interplay between the application of Article 3 and the provisions on international transfers as per Chapter V of the GDPR” (Nov. 18, 2021). GDPR Article 14, Paragraphs 5(a) and (b), and Article 27, Paragraph 2 also apply. In any event, we do not collect any personal data as defined in Art. 4 of the GDPR, except that which you voluntarily provide us, which of course is data collected “with consent” pursuant to GDPR Article 7. We do not pass such information on to any third parties or share it with anyone; we use it only to communicate with you as set forth above. If the GDPR did apply, we are neither a “controller” nor a “processor” because we collect all data about you directly from you.
                    </p>

                </div>
                <h2 className="text-xl font-semibold mb-2 mt-6">Your Rights and Choices</h2>
                <p className="mb-4">
                    You have the right to request access to the personal data we hold about you, request correction of any incorrect data, and request deletion of your data under certain circumstances.
                </p>
                <h2 className="text-xl font-semibold mb-2 mt-6">Contact Information</h2>
                <p className="mb-4">
                    If you have any questions about this Privacy Policy or our data practices, please contact us at <a href="mailto:support@relevantreach.io">support@relevantreach.io</a>.
                </p>
                <p className="mt-6 text-center text-gray-600">
                    The full Privacy Policy will be available soon. Thank you for your patience.
                </p>
                <p className="mt-6 text-center text-gray-600">
                    Last updated July 18, 2024
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
