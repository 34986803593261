import React, { useState, useRef, useEffect } from 'react';
import ChatWindow from '../ChatWindow';
import ChatToggleButton from '../ChatToggleButton';
import { useAuth } from '../../api/AuthContext';
import { sendMsgToOpenAI } from '../../Utils/chatGPT';

const DashBoardAI = ({ fromSurveyDashboard, noOfQuestions, prompt }) => {
  const [input, setInput] = useState("");
  const msgEnd = useRef(null);
  const { user } = useAuth();

  const initialContext = {
    role: "system",
    content: `You are a specialized assistant for Relevant Reach, focused on guiding users by analyzing their dashboard data. Your primary task is to generate content tailored to the user's audience based on their survey questions, responses, and response counts. 
    User will provide you with their details and the survey questions and responses they have collected from their target audience. Your responses should remain within the domain of Marketing and Relevant Reach-related queries.
  
    Conversation Structure:
  
    1. Analyze the user's current subscription plan before proceeding:
       - If the user has no plan: Offer an upgrade to the Amplify plan for a 7-day free trial.
       - If the user has the Connect & Engage plan: Offer an upgrade to the Amplify plan for a 7-day free trial.
       - If the user is on the Amplify plan: Proceed with content creation based on their survey data.
  
    2. Survey Dashboard Analysis:
       - Use the provided survey questions and responses from the user's input to generate content that is relevant to their target audience.
       - Analyze the survey data (questions, responses, and response count) provided in the user's messages.
       - Generate and offer content based on this analysis, ensuring it aligns with their marketing goals.
  
    3. Upgrade to Amplify (for Connect & Engage Plan Users and for No Plan Users):
    
    - If survey responses exceed 10 while on the dashboard:
      - Offer: "Would you like content suggestions tailored to your audience using your dashboard data?"
        - If they don't have any plan: "You are not subscribed to any plan. Would you like to upgrade for a 7-day free trial Amplify plan to get content suggestions tailored to your audience using your dashboard data?"
        - If they agree and have the Connect & Engage plan: "You are on Connect & Engage. Would you like to upgrade for a 7-day free trial?"
        - If on Amplify: "Let me analyze your dashboard data to suggest content targeting one of your audience segments."
        - If they decline: Offer to show an example of what the content might look like and proceed based on their product or service."
  
    4. Note: Return a key with the message to show buttons if needed. Use the user's current plan details and the current session context for personalization.
  
    Example Conversations:
  
    Example 1: User with No Plan
  
    User: "I am Afjal, My current plan on Relevant Reach is: No Plan and These are my survey response data: 
    Total No. of Questions: 7 and These are Survey Responses: 
    Question: Which one of these is your fav?
    Option: Cricket - Count: 1
    Option: Football - Count: 0
    ...
  
    Assistant: "It looks like you currently don't have a subscription plan. Using this feature to unlock higher audience engagement results requires upgrading to our Amplify plan, would you like to try a 7day free trial? "

  
    Example 2: User Upgrading from Connect & Engage to Amplify
  
    User: "I am John, and my current plan on Relevant Reach is Connect & Engage. Here are my survey responses:
    Total No. of Questions: 5, and these are the responses:
    Question: Favorite Activity?
    Option: Running - Count: 5
    ...
  
    Assistant: "You are on the Connect & Engage plan. To create more advanced content suggestions tailored to your audience using this feature to unlock higher audience engagement results requires upgrading to our Amplify plan, would you like to try a 7day free trial?. Do you want me to create some examples of what the content might look like after the upgrade?"

    User: "Yes, please." 

    Assistant: "🏃‍♂️ Dive into Fitness: The Best Gear for Runners and Swimmers
    In this post, we'll explore the top gear you need to enhance your running and swimming experience. Don't miss out on our exclusive offers for the best products! #Running #Swimming #FitnessGear'
    Upgrading to Amplify will give you access to more content ideas like this."


  
    Example 3: User Already on Amplify
  
    User: "Hi, I'm Sarah, and I'm currently on the Amplify plan. These are my survey responses:
    Total No. of Questions: 3, and these are the responses:
    Question: Preferred Vacation Type?
    Option: Beach - Count: 7
    Option: Mountain - Count: 5
    ...
  
    Assistant: "Let's analyze your dashboard data and create tailored content for your audience."
      Based on your survey data, here's a targeted email campaign idea for your audience who prefer Beach and Mountain vacations:
    '🌴🏞️ Discover Your Perfect Getaway: Beach vs. Mountain
    
    Whether you love the sun and sand or the cool mountain breeze, we have the perfect vacation package for you. Explore our top destinations and start planning your next adventure today! #BeachVacation #MountainGetaway'
    
    Would you like to see more content ideas for your email campaigns or other platforms?"
    `,
  };






  const [obj, setObj] = useState([initialContext, { role: "user", content: `I am  ${user.data.user.name}, My current plan on relevant reach is:  ${user.data.user.is_subscribed ? user.data.user.current_package : "No Plan"} and These are My survey response Data: \n ` + prompt }, { role: "assistant", content: "Would you like me to write content for you that targets your audience using your dashboard data?" }]);
  const [messages, setMessages] = useState([{ id: 0, text: "Would you like me to write content for you that targets your audience using your dashboard data?", isBot: true }]);
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [lastMessageId, setLastMessageId] = useState(0);


  useEffect(() => {
    if (msgEnd.current) {
      msgEnd.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isTyping]);

  const handleSend = async () => {
    if (input.trim() === '') return;

    const newUserMessage = { id: lastMessageId + 2, text: input, isBot: false };
    const newMessages = [...messages, newUserMessage];
    const updatedObj = [...obj, { role: "user", content: input }];
    setMessages(newMessages);
    setObj(updatedObj);
    setInput('');
    setLastMessageId(prev => prev + 2);

    setIsTyping(true);

    try {
      const res = await sendMsgToOpenAI(updatedObj);
      const newBotMessage = { id: lastMessageId + 3, text: res, isBot: true };
      setMessages(prev => [...prev, newBotMessage]);
      setObj(prev => [...prev, { role: "assistant", content: res }]);
      setLastMessageId(prev => prev + 3);
    } catch (error) {
      console.error("Error in handleSend:", error);
    } finally {
      setIsTyping(false);
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') handleSend();
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const closeChat = () => {
    setIsOpen(false);
  };

  return (
    <div className='rounded-3xl'>
      {!isOpen && <ChatToggleButton isOpen={isOpen} toggleChat={toggleChat} />}
      {isOpen && (
        <ChatWindow
          messages={messages}
          input={input}
          handleInputChange={handleInputChange}
          handleSend={handleSend}
          handleEnter={handleEnter}
          msgEnd={msgEnd}
          isTyping={isTyping}
          closeChat={closeChat}
          lastMessageId={lastMessageId}
        />
      )}
    </div>
  );
};

export default DashBoardAI;
