import React, { useState } from 'react';
import { useAuth } from '../api/AuthContext';
import { useNavigate } from 'react-router-dom';

const PaymentComponent = ({ plan }) => {
    const { user } = useAuth();
    const [amount] = useState(plan.amount);
    const navigate = useNavigate();

    const handlePayment = async () => {

        // navigate('/payment', { state: { amount, plan } });
        if (plan.title == "Connect & Engage" || plan.title == "Amplify") {
            navigate('/payment', { state: { amount, plan } });
        }
    };

    return (
        <button
            onClick={handlePayment}
            className="rounded mt-6 w-full py-2 px-4 border border-transparent  shadow-sm text-sm font-medium text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
        >
            {(plan.title == "Connect & Engage" || plan.title == "Amplify") ? 'Subscribe Now' : 'Coming Soon'}
        </button>
    );
};

export default PaymentComponent;
