import { Menu } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { useAuth } from '../api/AuthContext';


const Logout = () => {
    const { user, logout } = useAuth();
    return (
        <div>
            <div className="flex items-end justify-end mt-4 p-4 bg-gray-800 rounded-l h-30">
                <div className="flex items-center space-x-4">
                    <img src="https://ideogram.ai/assets/progressive-image/balanced/response/Ns6zJf-HSDmhV3-PgXL6UA" alt="Profile" className="h-10 w-10 rounded-full" />
                    <div>
                        <div className="text-white font-bold">{user.data.user.name}</div>
                        <div className="text-gray-400 text-xs">{user.data.user.email}</div>
                    </div>
                </div>
                <Menu as="div" className="relative -mt-6">
                    <Menu.Button className="flex items-center ">
                        <DotsVerticalIcon className="h-6 w-6 mb-4 text-gray-300" />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 mt-2 w-48 bg-white divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none">
                        <div className="py-1 ">

                            <Menu>
                                {({ active }) => (
                                    <button
                                        className={`${active ? 'bg-gray-100' : ''
                                            } flex justify-between w-full px-4 py-2 text-sm text-gray-700`}
                                        onClick={() => {
                                            logout();

                                        }}
                                    >
                                        Log Out
                                    </button>
                                )}
                            </Menu>
                        </div>
                    </Menu.Items>
                </Menu>
            </div>
        </div>
    )
}

export default Logout;