import React from 'react'
import Side from './Side'

const Invoice = () => {
  return (
    <div>
      <Side />
      Invoice</div>
  )
}

export default Invoice