import React from 'react';

const Subscription = ({ subscription, isActive, onUpgrade, onCancel }) => {
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatPackageName = (packageName) => {
    return packageName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className="w-full max-w-4x sm:w-9/12 md:w-8/12 lg:w-6/12 xl:w-5/12 mx-auto mt-10 p-4 sm:p-6 bg-white border border-gray-200 rounded-xl shadow relative">
      <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">My Subscription</h2>
      {subscription ? (
        <div className="border rounded p-4 relative">
          <div className="absolute top-0 right-0 m-2 px-3 py-1 bg-red-400 text-white text-xs font-semibold rounded-full">
            Trial Period
          </div>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
            <div>
              <h3 className="text-md sm:text-lg font-medium">{formatPackageName(subscription.package_id)}</h3>
              <p className="text-sm text-gray-600 font-poppins">
                This subscription will be automatically renewed every {subscription.billing_cycle}.
              </p>
              <p className="text-sm text-gray-600 font-poppins">
                Start From: {formatDate(subscription.start_date)}
              </p>
              <p className="text-sm text-gray-600 font-poppins">
                Trials End At: {formatDate(subscription.end_date)}
              </p>
              {/* <p className="text-sm text-gray-600 font-poppins">
                Credits Left: {subscription.credits ? (subscription.credits) : 'N/A'}
              </p> */}

              <div className="mt-4 space-x-2">
                {/* isActive Upgrade Else Subscribe Again */}



                {isActive ? (<button
                  onClick={onUpgrade}
                  className="px-4 py-2 bg-purple-500 text-white text-sm font-semibold rounded hover:bg-purple-600"
                >
                  Upgrade
                </button>) : (<button
                  onClick={onUpgrade}
                  className="px-4 py-2 bg-purple-500 text-white text-sm font-semibold rounded hover:bg-purple-600"
                >
                  Subscribe Again
                </button>)}

                {/* Show when isActive */}
                {isActive && (<button
                  onClick={onCancel}
                  className="px-4 py-2 bg-red-500 text-white text-sm font-semibold rounded hover:bg-red-600"
                >
                  Cancel Subscription
                </button>)}
              </div>
            </div>
            <span className={`mt-2 md:mt-0 ml-0 md:ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-poppins font-medium ${isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
              {isActive ? 'Active' : 'Deactive'}
            </span>
          </div>
        </div>
      ) : (
        <p>No active subscription found.</p>
      )}
    </div>
  );
};

export default Subscription;
