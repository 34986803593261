import React, { Profiler, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Spinner from './Components/Spinner';
import './App.css';
import Register from './Components/Register';
import ForgotPassword from './Components/ForgotPassword';
import { AuthProvider } from './api/AuthContext';
import ProtectedRoute from './api/ProtectedRoute';
import Invoice from './Components/Invoice';
import Subscribe from './Components/Subscribe';
import ChangePassword from './Components/ChangePassword';
import ProfilePage from './Components/ProfilePage';
import SurveyDashboard from './Components/Graph/SurveyDashboard';
import { SubscriptionTable } from './Components/SubscriptionTable';
import { PaymentGateway } from './Components/PaymentGateway';
import PrivacyPolicy from './Components/Privacy';
import NotFound from './Components/NotFound';

const Login = lazy(() => import('./Components/Login'));
const Dashboard = lazy(() => import('./Components/Dashboard'));
const ProjectTables = lazy(() => import('./Components/ProjectTables'));
const SurveyEditor = lazy(() => import('./Components/SurveyEditor'));
const Preview = lazy(() => import('./Components/Preview'));

const App = () => {
  const location = useLocation();
  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.key}
        classNames="page-transition"
        timeout={600}
      >
        <div>
          <Suspense fallback={<Spinner />}>
            <AuthProvider>
              <Routes location={location}>
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/invoice"
                  element={
                    <ProtectedRoute>
                      <Invoice />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="survey/my-survey/edit-survey/:id"
                  element={
                    <ProtectedRoute>
                      <SurveyEditor isEditMode={true} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="dashboard/edit-survey/:id"
                  element={
                    <ProtectedRoute>
                      <SurveyEditor isEditMode={true} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/subscribe"
                  element={
                    <ProtectedRoute>
                      <Subscribe />
                    </ProtectedRoute>
                  }
                />
                <Route path='/register' element={<Register />} />
                <Route path='/privacy' element={<PrivacyPolicy />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/dashboard/survey-dashboard/:slug' element={<ProtectedRoute><SurveyDashboard /></ProtectedRoute>} />
                <Route path='/survey/my-survey/survey-dashboard/:slug' element={<ProtectedRoute><SurveyDashboard /></ProtectedRoute>} />
                <Route path='/transactions' element={<ProtectedRoute><SubscriptionTable /></ProtectedRoute>} />
                <Route path='/payment' element={<ProtectedRoute><PaymentGateway /></ProtectedRoute>} />
                <Route path='/' element={<Login />} />
                <Route path='/login' element={<Login />} />
                <Route path='/survey/my-survey' element={<ProtectedRoute><ProjectTables fromHome={false} /></ProtectedRoute>} />
                <Route path='/create-survey' element={<ProtectedRoute><SurveyEditor /></ProtectedRoute>} />
                <Route path='/survey/my-survey/previewSurvey/:slug' element={<ProtectedRoute> <Preview /></ProtectedRoute>} />
                <Route path='/dashboard/previewSurvey/:slug' element={<ProtectedRoute> <Preview /></ProtectedRoute>} />
                <Route path='/survey/:slug' element={<Preview />} />
                <Route path='/user/profile' element={<ProfilePage />} />
                <Route path='/user/changepassword' element={<ChangePassword />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </AuthProvider>
          </Suspense>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
