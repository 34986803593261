import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../api/AuthContext';

const OptionSelector = ({ options, selectedOption, onSelect }) => {
    return (
        <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mt-4 lg:mt-8">
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`mt-4 px-4 py-2 rounded-lg shadow-md transition duration-200 ease-in-out transform hover:scale-105 focus:outline-none ${selectedOption === option.label ? 'text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                    onClick={() => onSelect(option.label)}
                >
                    <img src={option.imageSrc} alt={option.label} className="h-10 w-20 object-contain" />
                </button>
            ))}
        </div>
    );
};

export const PaymentGateway = () => {
    const location = useLocation();
    const { user } = useAuth();

    const navigate = useNavigate();
    const { amount, plan } = location.state || {};
    const options = [
        { label: 'Stripe', imageSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/1280px-Stripe_Logo%2C_revised_2016.svg.png' }
    ];
    const [selectedOption, setSelectedOption] = useState('Stripe');

    const handleSelect = (option) => {
        setSelectedOption(option);
    };

    const handlePayment = async () => {
        try {
            const response = await axios.post('https://onetapaisolutions.com/relevantreach/backend/public/api/create-checkout-session', {
                package_id: plan.package_id,
                amount: plan.stripe_price_id,
                user_id: user.data.user.id,
                email: user.data.user.email
            });

            const { gateway_link } = response.data;
            console.log(response.data);
            window.location.href = gateway_link;
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert('Payment failed');
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col items-center py-12">
            <header className="w-full">
                <div className="max-w-6xl mx-auto flex justify-between items-center px-4 md:px-8">
                    <button onClick={() => navigate(-1)} className="mr-3">
                        <FontAwesomeIcon icon={faArrowLeft} className="text-black text-lg" />
                    </button>
                    <h1 className="text-3xl font-bold text-gray-800 font-poppins">Checkout</h1>
                    <div className="flex items-center space-x-4">
                        <img src="/mainlogo.png" alt="logo" className="h-8 w-auto" />
                    </div>
                </div>
            </header>
            <main className="w-full flex flex-col items-center px-4 md:px-8 lg:px-16 py-8">
                <div className="flex flex-col md:flex-row w-full max-w-6xl mt-8 md:mt-16 space-y-8 md:space-y-0 md:space-x-8">
                    <div className="w-full lg:w-8/12 bg-white rounded-lg shadow-lg p-6">
                        <h2 className="text-xl font-semibold text-gray-700 font-poppins">Payment Method</h2>
                        <p className="text-gray-600 mt-2 mb-4 font-poppins">Select your preferred payment method below.</p>
                        <OptionSelector options={options} selectedOption={selectedOption} onSelect={handleSelect} />
                    </div>
                    <div className="w-full lg:w-4/12 bg-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold text-gray-700 font-poppins">Order Summary</h2>
                        <div className="mt-4">
                            <p className="text-gray-600 font-poppins">Plan Name <span className="float-right text-gray-800">{plan.title.toUpperCase()}</span></p>
                            <p className="text-gray-600 mt-2 font-poppins">Tax <span className="float-right text-gray-800 font-poppins">$0.00</span></p>
                        </div>
                        <hr className="my-4 border-t-1 border-gray-300" />
                        <p className="text-lg font-semibold text-gray-700 font-poppins">Total Price <span className="float-right text-gray-800">${amount}</span></p>
                        <button
                            onClick={handlePayment}
                            className="bg-violet-600 hover:bg-violet-700 py-2 px-4 w-full font-poppins rounded-lg mt-6 text-white transition duration-200 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-violet-600 focus:ring-offset-2"
                        >
                            Pay Now
                        </button>
                    </div>
                </div>
            </main>
            <footer className="w-full bg-white shadow-md py-4 mt-32">
                <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-8">
                    <p className="text-gray-600 font-poppins">© 2024 Relevant Reach. All rights reserved.</p>
                    <nav className="flex space-x-4 mt-4 md:mt-0">
                        <a href="#terms" className="text-gray-600 hover:text-gray-800 transition font-poppins">Terms & Conditions</a>
                        <a href="#privacy" className="text-gray-600 hover:text-gray-800 transition font-poppins">Privacy Policy</a>
                        <a href="#refund" className="text-gray-600 hover:text-gray-800 transition font-poppins">Refund & Cancellation</a>
                    </nav>
                </div>
            </footer>
        </div>
    );
};
