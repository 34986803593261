import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../api/AuthContext'; // Import your authentication context
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user, logout, } = useAuth(); // Access subscription value
  const menuRef = useRef(null);
  const [subscription, setSubscription] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);



  const handleToggleMenu = (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up to the document
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    fetchSubscription();
    if (showMenu) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showMenu]);


  const fetchSubscription = async () => {
    try {
      const response = await axios.get('https://onetapaisolutions.com/relevantreach/backend/public/api/user/latest-subscription', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tk')}`,
        },
      });
      setSubscription(response.data.data.subscription);
      setIsActive(response.data.data.subscription ? true : false);
    } catch (error) {
      setError('Failed to fetch subscription data');
    }
    finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader color="#2130B7" loading={loading} size={50} />
      </div>
    );
  }





  return (
    <div className="px-4 sm:px-8 py-4 sticky top-0 z-50">
      <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0">
        <div className="flex items-center w-full md:w-auto">
          {/* Add your logo or brand name here */}
        </div>
        <div className="flex items-center space-x-4 md:space-x-8 relative">
          {/* <div className="flex items-center space-x-2">
            <span className="text-black font-poppins">Credits:</span>
            <span className="text-white font-semibold">
              {subscription ? subscription.credits : 'No Subscription'}
            </span>
          </div> */}
          <i onClick={handleToggleMenu} className="cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
            </svg>
          </i>
          {showMenu && (
            <div ref={menuRef} className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
              <button
                onClick={() => { setShowMenu(false); logout(); }}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left font-poppins"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
