import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const initialData = { user_email: "" };
    const [inputData, setInputData] = useState(initialData);
    const [errors, setErrors] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();

    const handleData = (e) => {
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
    };

    const forgotPasswordApi = async () => {
        const formData = new FormData();
        formData.append('email', inputData.user_email);

        try {
            const response = await fetch('http://localhost:8000/api/forgotPassword', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                if (data.error) {
                    setErrors(data.message);
                } else {
                    setSuccessMessage(data.message);
                }
            } else {
                console.error('Failed to process request');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrors('Failed to process request');
        }
    };

    const sendForm = (e) => {
        e.preventDefault();
        if (!inputData.user_email) {
            alert("Email is mandatory");
        } else {
            forgotPasswordApi();
        }
    };

    return (
        <form onSubmit={sendForm}>
            <div className="min-h-screen flex items-center justify-center bg-[#101622]">
                <div className="flex flex-col md:flex-row w-full max-w-4xl bg-[#8de7eb] rounded-lg shadow-md overflow-hidden">
                    <div className="hidden md:flex md:w-1/2 p-8 bg-[#924BD5] items-center justify-center">
                        <img
                            src="https://demos.themeselection.com/marketplace/materio-mui-nextjs-admin-template/demo-1/images/illustrations/auth/v2-login-light.png"
                            alt="Forgot Password illustration"
                            className="w-full h-auto"
                        />
                    </div>
                    <div className="w-full md:w-1/2 p-8">
                        <img
                            src="/mainlogo.png"
                            alt="Forgot Password illustration"
                            className="w-full h-auto"
                        />
                        <p className="text-gray-600 mb-4 font-poppins">
                            Enter your email to reset your password
                        </p>
                        <div className="mb-4">
                            <label className="block text-gray-700">Email</label>
                            <input
                                type="email"
                                name='user_email'
                                value={inputData.user_email}
                                onChange={handleData}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
                            />
                            {errors && <p className="text-red-500">{errors}</p>}
                            {successMessage && <p className="text-green-500">{successMessage}</p>}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-indigo-600 text-white py-2 rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-300"
                        >
                            Reset Password
                        </button>
                        <div className="mt-6 text-center">
                            <p className="text-gray-600">
                                Remember your password?{' '}
                                <a href="/" className="text-indigo-600 hover:underline">
                                    Log In
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ForgotPassword;
