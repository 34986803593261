import React, { useState } from 'react';
import Side from './Side';
import Header from './Header';

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your password update logic here
    console.log('Password updated');
  };

  return (
    <div className="min-h-screen flex bg-gray-100">
      <Side />
      <div className="flex-1 flex flex-col">
        <Header />
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
          <div className="bg-white mb-10 shadow-lg rounded-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-semibold mb-6 font-poppins">Change Password</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-700 font-semibold font-poppins" htmlFor="currentPassword">
                  Current Password:
                </label>
                <input
                  id="currentPassword"
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder="+8 characters"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold font-poppins" htmlFor="newPassword">
                  New Password:
                </label>
                <input
                  id="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="+8 characters"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold font-poppins" htmlFor="confirmPassword">
                  Confirm New Password:
                </label>
                <input
                  id="confirmPassword"
                  type="text"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="+8 characters"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  required
                />
              </div>
              <div>
                <button type="submit" className="bg-gray-800 text-white px-4 py-2 rounded-md font-poppins">
                  Update Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
