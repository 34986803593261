import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function BarGraph({ question, answers }) {
    const options = answers.map(answer => answer.option);
    const counts = answers.map(answer => answer.count);

    return (
        <div>
            <h2 className="text-s font-normal mb-4">{question}</h2>
            <BarChart
                xAxis={[{ scaleType: 'band', data: options }]}
                series={[{ data: counts }]}
                height={300}
                barCategoryGap="30%" // Adjust this value to reduce the bar width
                barGap="10%" // Adjust this value if needed
            />
        </div>
    );
}
