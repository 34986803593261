// src/components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-blue-500 to-purple-500">
      <div className="text-center p-10 bg-white bg-opacity-10 rounded-lg">
        <img src="/mainlogo.png" alt=" Website Logo" className="mx-auto mb-4" />
        <h1 className="text-9xl font-bold text-white">404</h1>
        <p className="text-2xl text-white mt-4">Oops! The page you are looking for does not exist.</p>
        <Link to="/" className="mt-6 inline-block px-6 py-3 bg-white text-blue-500 font-semibold rounded-lg hover:bg-blue-500 hover:text-white transition duration-300">
          Go to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
