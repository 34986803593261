import axiosApi from "./axiosApi";


export async function login(email, password) {
    const req = await axiosApi.post('/api/login', { email, password });
    console.log(req.data);
    if (!req.data.error) {
        console.log(req.data.authorisation.token);
        localStorage.setItem("tk", JSON.stringify(req.data.authorisation.token));
    }
    return req.data;
}

export async function logout() {
    const req = await axiosApi.post('/api/logout');
    localStorage.removeItem("tk");
    return req.data;
}



export default function register(user) {
    return axiosApi.post("/api/register", user)
}

export async function currentUser() {
    const req = await axiosApi.get("/api/me")
    console.log(req.data);
    return req.data
}




export async function getSubscriptionStatus() {
    const req = await axiosApi.get("/api/user/latest-subscription")
    console.log(req.data);
    return req.data
}




export async function sendPasswordResetEmail(email) {
    const req = await axiosApi.post("/auth/forget-password", { email })
    return req.data
}

export async function sendResetPassword(payload) {
    const req = await axiosApi.post("/auth/reset-password", payload)
    return req.data
}
