import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon, ChartBarIcon, DocumentIcon, UserIcon, LockClosedIcon, BookOpenIcon, ShieldCheckIcon, ArrowsExpandIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/outline';
import Logout from './Logout';

// SidebarItem Component
const SidebarItem = ({ icon, label, onClick, path, subItems }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (path) {
            setIsActive(location.pathname === path);
        } else if (subItems) {
            setIsActive(subItems.some(subItem => location.pathname === subItem.path));
            setIsExpanded(subItems.some(subItem => location.pathname === subItem.path));
        }
    }, [location.pathname, path, subItems]);
    console.log(location.pathname)

    const handleClick = () => {
        if (subItems) {
            setIsExpanded(!isExpanded);
        } else if (path) {
            navigate(path, { replace: true });
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <div>
            <div
                className={`my-2 cursor-pointer font-poppins px-4 py-2 flex items-center justify-between rounded-lg ${isActive ? 'bg-purple-500 text-white' : 'text-gray-600'}`}
                onClick={handleClick}
            >
                <div className="flex items-center space-x-2">
                    {icon}
                    <span className='font-poppins'>{label}</span>
                </div>
                {subItems && (
                    isExpanded ? (
                        <ChevronDownIcon className="h-5 w-5 text-gray-600" />
                    ) : (
                        <ChevronRightIcon className="h-5 w-5 text-gray-600" />
                    )
                )}
            </div>
            {isExpanded && subItems && (
                <div className="ml-8">
                    {subItems.map((subItem, index) => (
                        <div
                            key={index}
                            className={`my-2 font-poppins cursor-pointer px-4 py-2 flex items-center justify-between rounded-lg ${location.pathname === subItem.path ? 'bg-purple-500 font-poppins text-white' : 'text-gray-600'}`}
                            onClick={() => navigate(subItem.path)}
                        >
                            <span>{subItem.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

// Side Component
const Side = ({ setCurrentView }) => {
    const navigate = useNavigate();

    const handleClk = () => {
        navigate('/dashboard');
    };

    return (
        <div className="w-64 h-screen  text-black bg-gray-100 p-4 font-poppins">
            <div className="flex items-center justify-center mb-8 cursor-pointer font-poppins">
                <img onClick={handleClk} src="/mainlogo.png" alt="Logo" className="h-8 mt-2 w-auto" />
            </div>
            <SidebarItem
                icon={<HomeIcon className="h-5 w-5 " />}
                label="Dashboards"
                path="/dashboard"

                onClick={() => {
                    setCurrentView('default');
                    navigate('/dashboard');
                }}

            />
            <SidebarItem
                icon={<ChartBarIcon className="h-5 w-5" />}
                label="Survey"
                subItems={[
                    { label: 'My Survey', path: '/survey/my-survey' },
                    { label: 'Create Survey', path: '/create-survey' },
                ]}
            />
            <SidebarItem
                icon={<BookOpenIcon className="h-5 w-5" />}
                label="Subscription"
                path="/subscribe"
                onClick={() => {
                    setCurrentView('subscription');
                    navigate('/subscribe');
                }}
            />
            <SidebarItem
                icon={<DocumentIcon className="h-5 w-5" />}
                label="Transactions"
                path="/transactions"
                onClick={() => {
                    setCurrentView('transactions');
                    navigate('/transactions');
                }}
            />

            {/* 
            {/* <SidebarItem
                icon={<LockClosedIcon className="h-5 w-5" />}
                label="Your Credits"
                path="/roles-permissions"
                onClick={() => {
                    setCurrentView('your-credits');
                    navigate('/roles-permissions');
                }}
            /> */}
            {/* <SidebarItem
                icon={<ShieldCheckIcon className="h-5 w-5" />}
                label="Support"
                path="/auth-pages"
                onClick={() => {
                    setCurrentView('support');
                    navigate('/auth-pages');
                }}
            /> */}
            <SidebarItem
                icon={<ChartBarIcon className="h-5 w-5" />}
                label="My Account"
                subItems={[
                    { label: 'Profile', path: '/user/profile' },
                    // { label: 'Change Password', path: '/user/changepassword' },
                ]}
            />

            {/* add Logout Button in the bottom of side */}
            {/* Logout
             */}

            {/* <Logout />
             */}

            {/* align this in bottom of left corner
            <div className="absolute bottom-0 left-0 w-full">
                <Logout />
            </div> */}


        </div >
    );
};

export default Side;
