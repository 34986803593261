import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import BarGraph from './BarGraph';
import HorizontalBars from './HorizontalGraph';
import ScatterGraph from './ScatterGraph';
import BasicPie from './PieChart';
import AIbot from '../AIbot';
import { ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import DashBoardAI from './DashBoardAI';

const SurveyDashboard = ({ }) => {
    const navigate = useNavigate();
    const [surveyData, setSurveyData] = useState([]);
    const [noOfQuestions, setNoOfQuestions] = useState(0);
    const [loading, setLoading] = useState(true);
    const { slug } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchSurveyData = async () => {
            try {
                const response = await axios.post('https://onetapaisolutions.com/relevantreach/backend/public/api/getResponse', { slug });
                const processedData = processSurveyData(response.data);
                setSurveyData(processedData);
                setNoOfQuestions(processedData.length);

            } catch (error) {
                setLoading(false);
                console.error('Error fetching survey data:', error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchSurveyData();
    }, [slug]);

    const processSurveyData = (data) => {
        const radiogroupQuestions = data.filter(survey => survey.type === 'radiogroup');

        // Determine the number of questions to change
        const numberOfChanges = Math.floor(radiogroupQuestions.length / 2);
        const selectedIndexes = new Set();

        // Randomly select indexes to change
        while (selectedIndexes.size < numberOfChanges) {
            const randomIndex = Math.floor(Math.random() * radiogroupQuestions.length);
            selectedIndexes.add(randomIndex);
        }

        const processedData = data.map((survey, index) => {
            if (survey.type === 'radiogroup' && selectedIndexes.has(index)) {
                return { ...survey, type: 'piechart' };
            }
            return survey;
        });

        return processedData;
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <ClipLoader color="#2130B7" loading={loading} size={50} />
            </div>
        );
    }

    return (
        <div
            className="min-h-screen bg-gradient-to-r from-[#2130B7] to-[#AD6E7F] p-4 sm:p-8 bg-cover bg-no-repeat"
            style={{ backgroundImage: `url(/bgimage.jpeg)` }}
        >
            <div className="mb-6 flex  items-center justify-between">

                <img
                    onClick={() => navigate('/dashboard')}
                    src="/mainlogo.png"
                    alt="Logo"
                    className="h-8 mt-2 w-auto cursor-pointer"
                />
                <button onClick={() => navigate('/dashboard')} className="ml-3 font-bold py-1 px-3 bg-purple-500 text-white rounded-full border-2 border-purple-500 hover:bg-purple-700 transition duration-300">
                    Go to Dashboard
                </button>
            </div>
            <h1 className="text-[#291010] font-bold font-poppins text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-6 text-center">
                Survey Response Dashboard
            </h1>
            {
                surveyData.length == 0 ? <div className='flex justify-center items-center'><h1 className='font-semibold text-4xl font-poppins text-red-600'>No Data Available</h1></div> : ''
            }

            <div className="grid grid-cols-1 gap-4 sm:gap-6 lg:grid-cols-2">
                {surveyData.map((survey, index) => (
                    <div
                        key={index}
                        className={`bg-white shadow-lg rounded-lg p-4 font-poppins sm:p-6 ${survey.type === 'checkbox' ? 'col-span-1 lg:col-span-2' : ''}`}
                    >
                        {survey.type === "radiogroup" ? (
                            <BarGraph question={survey.question} answers={survey.answers} />
                        ) : survey.type === "checkbox" ? (
                            <HorizontalBars question={survey.question} answers={survey.answers} />
                        ) : survey.type === "scatter" ? (
                            <ScatterGraph question={survey.question} answers={survey.answers} />
                        ) : (
                            <BasicPie question={survey.question} answers={survey.answers} />
                        )}
                    </div>
                ))}
            </div>
            <DashBoardAI
                fromSurveyDashboard={true}
                noOfQuestions={noOfQuestions}
                prompt={
                    "Total No. of Questions: " +
                    noOfQuestions +
                    " and These are Survey Responses: " +
                    surveyData.map(survey =>
                        `\nQuestion: ${survey.question}\n` +
                        survey.answers.map(answer =>
                            `Option: ${answer.option} - No of users responded: ${answer.count}`
                        ).join('\n')
                    ).join('\n\n')
                }
            />
        </div>
    )
}



export default SurveyDashboard;
