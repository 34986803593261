import React, { useState, useEffect } from 'react';
import { useAuth } from '../api/AuthContext'; // Adjust the path to your AuthContext
import Header from './Header';
import Side from './Side';
import { Link } from 'react-router-dom';
import Subscription from './UserSubscriptions';
import axios from 'axios';
import AIbot from './AIbot';

function ProfilePage() {
  const { user } = useAuth();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [subscription, setSubscription] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) {
      fetchSubscription();
      console.log(user);
      setUsername(user.data.user.name); // Assuming user object has a username property
      setEmail(user.data.user.email);
    }
  }, [user]);

  const fetchSubscription = async () => {
    try {
      const response = await axios.get('https://onetapaisolutions.com/relevantreach/backend/public/api/user/latest-subscription', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tk')}`,
        },
      });
      console.log(response.data);
      setSubscription(response.data.data.subscription);
      setIsActive(response.data.data.subscription ? true : false);
    } catch (error) {
      setError('Failed to fetch subscription data');
    }
  };

  return (
    <div className="min-h-screen flex bg-gradient-to-r from-[#2130B7] to-[#AD6E7F]">
      <Side />
      <div className="flex-1 flex flex-col bg-gradient-to-r from-[#2130B7] to-[#AD6E7F]">
        <Header />
        <div className="flex-1 flex flex-col items-center justify-center bg-gradient-to-r from-[#2130B7] to-[#AD6E7F] p-4">
          <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">
            <div className="flex flex-col sm:flex-row items-center justify-between border-b pb-4 mb-4">
              <div className="flex items-center">
                <div className="bg-gray-300 rounded-full w-16 h-16 flex items-center justify-center text-2xl font-bold text-gray-700 font-poppins">
                  {username ? username.charAt(0).toUpperCase() : 'U'}
                </div>
                <div className="ml-4">
                  <div className="text-lg font-semibold font-poppins">{username}</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row">
              <div className="w-full sm:w-1/4 mb-4 sm:mb-0">
                <nav className="space-y-4">
                  <Link to="/user/profile" className="block text-gray-700 font-semibold font-poppins">
                    Profile
                  </Link>
                  {/* <Link to="/user/changepassword" className="block text-gray-700 font-semibold">
                    Change Password
                  </Link> */}
                </nav>
              </div>
              <div className="w-full sm:w-3/4 sm:pl-8">
                <div className="space-y-4">
                  <div>
                    <label className="block text-gray-700 font-semibold font-poppins" htmlFor="username">Username:</label>
                    <input
                      id="username"
                      type="text"
                      value={username}
                      // onChange={(e) => setUsername(e.target.value)}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm font-poppins"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 font-semibold font-poppins" htmlFor="email">Email:</label>
                    <input
                      id="email"
                      type="email"
                      value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm font-poppins"
                    />
                  </div>
                  <div>
                    {/* <button className="bg-gray-800 text-white px-4 py-2 rounded-md">
                      Save
                    </button> */}
                  </div>
                </div>
              </div>
              {/* <AIbot /> */}
            </div>
          </div>

          <footer className="mt-4 text-gray-600 font-poppins">
            2024 © all rights reserved.
          </footer>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
